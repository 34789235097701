import * as React from 'react';
import { graphql, PageProps } from 'gatsby';

const TypegenPage = ({ data }: PageProps<Queries.TypegenPageQuery>) => {
  return (
    <main className="bg-brand-cyan text-white">
      <p
        className={
          'bg-sky-700 px-4 py-2 text-white hover:bg-sky-800 sm:px-8 sm:py-3'
        }
      >
        Site title: {data.site?.siteMetadata?.title}
      </p>
      {/*<p>{data.strapiHomepage?.intro?.content?.data?.content}</p>*/}
      <hr />
      <p>Query Result:</p>
      <pre>
        <code>{JSON.stringify(data, null, 2)}</code>
      </pre>
    </main>
  );
};

export default TypegenPage;

export const query = graphql`
  query TypegenPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
